import { Box, Grid2, Stack, Theme, useMediaQuery } from '@mui/material';

import { useMemo } from 'react';
import MegaMenuSection from './MegaMenuSection';
import MobileSection from './MobileSection';
import Navigation from './Navigation';
import Notification from './Notification';
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ fullWidth }: { fullWidth: boolean }) => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const megaMenu = useMemo(() => <MegaMenuSection />, []);
  return (
    <Grid2 sx={{ width: '100%' }}>
      <Grid2 size={12} display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" justifyContent="flex-start">
          {fullWidth && <Navigation />}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
          {fullWidth && megaMenu}
          <Notification />
          {!matchesXs && <Profile />}
          {matchesXs && <MobileSection />}
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default HeaderContent;
